import { React, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { InformationCircleIcon } from "@heroicons/react/outline"
import { nameField } from "./contact.module.css"
import CalendlyButton from "../components/calendly"

const ContactPage = () => {
  const [email, setEmail] = useState(null)
  const [message, setMessage] = useState(null)
  const [name, setName] = useState(null)
  const [formSent, setFormSent] = useState(false)
  const [formSubmitted, setFormSubmitted] = useState(false)
  const [timer, setTimer] = useState(null)

  const startTimer = e => {
    if (!timer) setTimer(new Date())
  }

  const submit = e => {
    e.preventDefault()

    if (formSent || !timer || (new Date() - timer) / 1000 < 3) return

    const XHR = new XMLHttpRequest()
    XHR.addEventListener("load", () => {
      setFormSent(true)
    })
    // Set up our request
    XHR.open(
      "POST",
      "https://potax7wj94.execute-api.eu-central-1.amazonaws.com/contact-us"
    )
    XHR.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
    XHR.send(JSON.stringify({ email: email, message: message, name: name }))
    setFormSubmitted(true)
  }

  return (
    <Layout>
      <SEO title="Contact" />
      <div className="py-6 " id="contact">
        <div className="container px-3 mx-auto flex flex-wrap">
          <div className="w-full md:w-1/2 px-3">
            <h1 className="my-4 text-4xl text-black font-medium">
              Get in touch
            </h1>
            <p className="text-lg">Want to see how the tool works?</p>
            <CalendlyButton />
            <p className="text-lg">
              Other questions? Send us a message through the chat in the bottom
              right corner or write us a message here.
            </p>
            <div className="flex mt-2">
              <InformationCircleIcon className="h-8 w-8 mr-2 text-primary" />
              <p className="text-lg">
                We'll get back to you within a few hours.
              </p>
            </div>

            {!formSent && (
              <div className="w-full pt-3">
                <form onSubmit={submit} method="POST">
                  <div className="-mx-3 mb-6">
                    <div className="w-full px-3">
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500"
                        id="email"
                        name="email"
                        required
                        type="email"
                        placeholder="Your Email"
                        onFocus={startTimer}
                        onChange={e => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className={`${nameField} mb-6`}>
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="message"
                    >
                      Name
                    </label>
                    <input
                      className="nameField appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-gray-500"
                      id="name"
                      name="name"
                      type="text"
                      placeholder="Your Name"
                      autoComplete="off"
                      tabIndex="-1"
                      onChange={e => setName(e.target.value)}
                    />
                  </div>
                  <div className="mb-6">
                    <label
                      className="block text-gray-700 text-sm font-bold mb-2"
                      htmlFor="message"
                    >
                      Message
                    </label>
                    <textarea
                      className="no-resize appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none  focus:border-gray-500 h-56 resize-none"
                      id="message"
                      required
                      placeholder="Your Message..."
                      name="message"
                      onFocus={startTimer}
                      onChange={e => setMessage(e.target.value)}
                    ></textarea>
                  </div>
                  <button
                    className="bg-primary w-full block disabled:opacity-50 text-white font-bold py-2 px-4 rounded"
                    type="submit"
                    disabled={formSubmitted}
                  >
                    Send message
                  </button>
                </form>
              </div>
            )}
            {formSent && (
              <p className="text-lg my-12 pb-48">
                Thanks - we'll get back to you as soon as possible
              </p>
            )}
          </div>
          <div className="w-full md:w-1/2 px-3">
            <h1 className="my-4 text-4xl text-black font-medium">
              How to find us
            </h1>
            <p>
              <i className="fas fa-handshake text-primary text-lg"></i> Come and
              visit us!
            </p>
            <iframe
              title="Where to find us!"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d576943.5955774367!2d12.457693165436078!3d55.6074796805148!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4653a3e6bbe82b21%3A0x90690fc567c4b9d6!2sBredgatan%204%2C%20211%2030%20Malm%C3%B6!5e0!3m2!1sen!2sse!4v1638345876571!5m2!1sen!2sse"
              width="100%"
              height="80%"
              frameBorder="0"
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            ></iframe>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ContactPage
